import Grid from "@mui/material/Grid2";
import "./Footer.css";

export const Footer = () => {
  return (
    <Grid container>
      <Grid className="footer">
      <div>CASA ENCONTRO<br />Rua dos Libaneses, 1621</div>
      <div>Terças e quintas <br /> das 14: 00 às 17 horas.</div>
      </Grid>
    </Grid>
  );
};
