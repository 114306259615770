//import React from 'react'
import Grid from "@mui/material/Grid2";
import { Header } from "./Header";
import { Body } from "./Body";
import { Footer } from "./Footer";

export const Home = () => {
  return (
    <Grid
      container
      //columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      justifyContent="center"
      //alignItems="center"
      //style={{ minHeight: "100vh" }}
    >
      <Grid size={{ xs: 12, md: 8, lg: 6 }}>
        <Grid>
          <Header />
        </Grid>
        <Grid>
          <Body />
        </Grid>
        <Grid>
          <Footer />
        </Grid>
      </Grid>
    </Grid>
  );
};
