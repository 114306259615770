import "./Body.css";

import artesanato from "../assets/images/artesanato.png";
import bordado from "../assets/images/bordado.png";
import croche from "../assets/images/croche.png";
import brecho from "../assets/images/brecho.png";

import Grid from "@mui/material/Grid2";

export const Body = () => {
  return (
    <Grid container>
      <Grid className="box">
        <div className="box-text">
          <div className="text-title">Artesanato</div>
          <div>
            Em nossas mãos, a arte ganha vida! Na Casa Encontro, você encontra
            uma variedade de artesanatos feitos com muito amor e criatividade.
            Cada peça é única e carrega um pedacinho da alma de quem a criou.
          </div>
        </div>
        <div className="box-img">
          <img src={artesanato} alt="Artesanato" />
        </div>
      </Grid>
      <Grid className="box-reverse">
        <div className="box-img">
          <img src={bordado} alt="Bordado" />
        </div>
        <div className="box-text">
          <div className="text-title">Bordado</div>
          <div>
            Um bordado conta mil histórias! Na Casa Encontro, você encontra
            peças exclusivas com bordados feitos à mão, que trazem um toque de
            elegância e personalidade para qualquer ambiente.
          </div>
        </div>
      </Grid>
      <Grid className="box">
        <div className="box-text">
          <div className="text-title">Croche</div>
          <div>
            O crochê é a nossa paixão! ❤ Na Casa Encontro, você encontra peças
            delicadas e charmosas feitas à mão, como tapetes, bolsas, roupas e
            muito mais. Venha se encantar com a beleza e a versatilidade dessa
            técnica milenar.
          </div>
        </div>
        <div className="box-img">
          <img src={croche} alt="Crochê" />
        </div>
      </Grid>

      <Grid className="box-brecho">
        <div className="box-brecho-title">Brechó</div>
        <div className="box-brecho-text">
          <p>
            Nosso brechó é um verdadeiro tesouro escondido! Recebemos
            diariamente doações de peças únicas e de alta qualidade, que vão
            desde roupas e acessórios vintage até itens de decoração exclusivos.
            Aqui, você encontra aquele look especial para qualquer ocasião, e o
            melhor: com preços super acessíveis!
          </p>
        </div>
        <img src={brecho} className="box-brecho-img" alt="brechó" />
        <div className="box-brecho-text">
          <p>
            Buscamos o equilíbrio entre moda e sustentabilidade. ♻️ Nem todas as
            doações são expostas em nosso brechó, apenas as peças que passam por
            nossa cuidadosa seleção. As demais são repassadas para outros
            brechós da cidade e região em eventos periódicos, garantindo que
            nada seja desperdiçado e promovendo a economia circular.
          </p>
        </div>
      </Grid>
    </Grid>
  );
};
