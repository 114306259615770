import "./Header.css";
import Grid from "@mui/material/Grid2";
import casa from "../assets/images/casa.png";

export const Header = () => {
  return (
    <Grid justifyContent="center" container>
      <Grid>
        <Grid id="grid-logo">
          <img src={casa} className="header-img" alt="logo" />
        </Grid>
        <Grid>
          <span className="header-text">
            CASA <br />
            ENCONTRO
          </span>
        </Grid>
        <Grid id="grid-presentation">
          <div className="presentation">
            <p>
              Localizada no coração do Carmo, em Araraquara, somos um espaço acolhedor onde a
              comunidade se reúne para compartilhar histórias, sorrisos e muita
              criatividade.✨ Abrimos nossas portas todas as terças e quintas,
              entre 14:00 e 17:00 horas.
            </p>
            <p>
              A Casa Encontro é mais que um brechó, é um ponto de encontro para
              a comunidade do Carmo. Aqui, você encontra peças exclusivas, desde
              roupas e acessórios vintage até artesanatos, bordados e crochê feitos com muito
              carinho pelas nossas artesãs. 
            </p>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
