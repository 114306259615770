import "./App.css";
//import {Auth} from './components/Auth';
//import {Movies} from './components/Movies';
import { Home } from "./components/Home";

function App() {
  return (
    <div className="App">
       <Home/> 
     {
     /*  <Auth />
      <Movies/>
       */}
    </div>
  );
}

export default App;
